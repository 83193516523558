import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>الصفحة غير موجودة</h1>
    <Link className="button primary" to="/">
      عدَ إلى الصفحة الرئيسة
    </Link>
  </Layout>
);

export default NotFoundPage;
